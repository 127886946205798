import Vue from 'vue'
import vuex from 'vuex';
import createLogger from 'vuex/dist/logger';  // 修改日志

Vue.use(vuex)

const debug = process.env.NODE_ENV !== 'production'; // 开发环境中为true，否则为false

export default new vuex.Store({
    state:{
        language: 'cn',
        isLogin: false,
        userInfo: {},
        token: '',
        menus: [],
        permissions: [],
    },
    mutations:{
        setLanguage(state, language){
            state.language = language;
            localStorage.setItem('language', language);
        },
        setIsLogin(state, isLogin){
            state.isLogin = isLogin;
            localStorage.setItem('isLogin', isLogin);
        },
        setUserInfo(state, userInfo){
            state.userInfo = userInfo;
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
        },
        setToken(state, token){
            state.token = token;
            localStorage.setItem('token', token);
        },
        setMenus(state, menus){
            state.menus = menus;
            localStorage.setItem('menus', JSON.stringify(menus));
        },
        setPermissions(state, permissions){
            state.permissions = permissions;
            localStorage.setItem('permissions', JSON.stringify(permissions));
        },
        
    },
    actions:{
    },
    getters:{
        language(state){
            return state.language;
        },
        isLogin(state){
            return state.isLogin;
        },
        userInfo(state){
            return state.userInfo;
        },
        token(state){
            return state.token;
        },
        menus(state){
            return state.menus;
        },
        permissions(state){
            return state.permissions;
        },
    },
    plugins: debug ? [createLogger()] : [] // 开发环境下显示vuex的状态修改
})