import request from '@/utils/request';

export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data: data
    });
}

export function register(data) {
    return request({
        url: '/register',
        method: 'post',
        data: data
    });
}

export function forgotpw(data) {
    return request({
        url: '/forgotpw',
        method: 'post',
        data: data
    });
}

export function getUserList(data) {
    return request({
        url: '/admin_user/list',
        method: 'post',
        data: data
    });
}

export function getUserInfo(id) {
    return request({
        url: '/admin_user/info/' + id,
        method: 'get',
    });
}

export function addUser(data) {
    return request({
        url: '/admin_user/add',
        method: 'post',
        data: data
    });
}

export function editUser(id, data) {
    return request({
        url: '/admin_user/edit/' + id,
        method: 'put',
        data: data
    });
}

export function delUser(data) {
    return request({
        url: '/admin_user/del',
        method: 'post',
        data: data
    });
}

export function editInfo(id, data) {
    return request({
        url: '/admin_user/edit_info/' + id,
        method: 'put',
        data: data
    });
}
