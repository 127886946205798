<template>
    <div class="login-container">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">
            <h3 class="login-title">欢迎登录</h3>
            <el-form-item porp="username">
                <el-input ref="username" v-model="loginForm.username" placeholder="请输入账号" name="username" type="text" tabindex="1" autocomplete="on">账号</el-input>
            </el-form-item>
            <el-form-item porp="password">
                <el-input ref="password" v-model="loginForm.password" placeholder="请输入密码" name="password" type="text" tabindex="2" autocomplete="on" @keyup.enter.native="submitForm">
                    密码
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button :loading="loading" type="primary" style="width: 100%" @click.native.prevent="submitForm">登录</el-button>
            </el-form-item>
            <el-form-item>
                <el-col :span="6">
                    <a href="/register">立即注册！</a>
                </el-col>
                <el-col :span="6" :offset="12">
                    <a href="/forgotpw">忘了密码？</a>
                </el-col>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { login } from '@/utils/servers/adminUser.js';
import { getRoleInfo } from '@/utils/servers/adminRole.js';
export default {
    name: 'Login',
    data() {
        return {
            loginForm: {
                username: '',
                password: ''
            },
            loginRules: {
                username: [{ required: true, trigger: 'blur' }],
                password: [{ required: true, trigger: 'blur' }]
            },
            loading: false
        };
    },
    methods: {
        ...mapMutations({ setIsLogin: 'setIsLogin', setUserInfo: 'setUserInfo', setToken: 'setToken', setMenus: 'setMenus', setPermissions: 'setPermissions' }),
        submitForm() {
            login(this.loginForm).then(res => {
                console.log(res);
                let { data, code, msg } = res;
                console.log(data, code, msg);
                if (code == 1) {
                    this.$message.success(msg);
                    // 登录成功
                    this.setIsLogin(true);
                    // 保存用户信息
                    this.setUserInfo(data.userInfo);
                    // 保存Token信息
                    this.setToken(data.token);
                    //获取当前用户角色权限
                    this.getRoleInfo(data.userInfo.role_id);
                    // 跳转首页
                    this.$router.push({ path: '/' });
                } else {
                    this.$message.error(msg);
                }
            });
        },
        getRoleInfo(id) {
            getRoleInfo(id).then(res => {
                var data = res;
                if (data.code == 1) {
                    // 保存菜单信息
                    this.setMenus(data.data.menus);
                    this.setPermissions(data.data.permissions);
                    location.reload();
                } else {
                    this.$message.error(data.msg);
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.login-container {
    width: 100vw;
    height: 100vh;
    background-image: url('~@/assets/img/login-bg.jpg');
    background-size: cover;
    overflow: hidden;
}
.login-form {
    border-radius: 15px;
    margin: auto;
    margin-top: 180px;
    width: 350px;
    padding: 25px 35px 25px 35px;
    background: aliceblue;
}
.login-title {
    margin: 0px auto 48px auto;
    text-align: center;
    font-size: 40px;
}
</style>
