import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router/index.js';

const request = axios.create({
    baseURL: '/admin', //设置基础URL
    timeout: 15000 //设置超时时间
});

request.interceptors.request.use(
    config => {
        // 从本地存储中获取 JWT 令牌
        const token = localStorage.getItem('token');
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    err => {
        return Promise.resolve(err);
    }
);

// http response 拦截器
request.interceptors.response.use(
    response => {
        const data = response.data;

        // 根据返回的code值来做不同的处理(和后端约定)
        switch (data.code) {
            case 401:
                // 未登录 清除已登录状态
                router.push('login');
                break;
            case 403:
                // 没有权限

                break;
            case 500:
                // 错误
                if (data.message !== null) {
                    Message.error(data.message);
                } else {
                    Message.error('未知错误');
                }
                break;
            default:
                return data;
        }
        return data;
    },
    err => {
        const {
            response: { data, status, statusText }
        } = err;
        return Promise.resolve(err);
    }
);

export default request;
