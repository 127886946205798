<template>
    <div>
        <div class="header">
            <div class="open">
                <span v-if="!isCollapse" class="el-icon-s-fold" @click="chanMenu"></span>
                <span v-if="isCollapse" class="el-icon-s-unfold" @click="chanMenu"></span>
            </div>
            <div class="user-info">
                <el-dropdown>
                    <span class="el-dropdown-link">
                        <img :src="userInfo.user_img" class="user-img">
                        {{ userInfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item><a href="/info">个人中心</a></el-dropdown-item>
                        <el-dropdown-item @click.native.prevent="loginOut">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    props:['isCollapse'],
    data(){
        return{
        }
    },
    computed:{
        ...mapGetters(["userInfo"])
    },
    methods:{
        ...mapMutations({setIsLogin:"setIsLogin", setUserInfo:"setUserInfo", setMenus:'setMenus', setPermissions:'setPermissions',}),
        chanMenu(){
            this.$emit('chanMenu')
        },
        loginOut(){
            this.setIsLogin(false)
            this.setUserInfo({})
            this.setMenus([]);
            this.setPermissions([]);
            localStorage.removeItem('isLogin')
            localStorage.removeItem('userInfo')
            localStorage.removeItem('menus')
            localStorage.removeItem('permissions')
            this.$router.push({ path: '/login'})
        },
    }
}
</script>

<style lang="less" scoped>
.header{
    display: flex;
    justify-content: space-between;
    height:50px;
    background: #fff;
    color: #34385c;
    line-height: 50px;
    box-shadow: 0 1px 5px rgba(000, 000, 000, 0.12);
    .open{
        font-size: 20px;
    }
    .user-info{
        margin-right: 20px;
        .user-img {
            margin-right: 8px;
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 5px;
        }
    }
}
</style>