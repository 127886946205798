import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/views/layout/index.vue';
import Login from '@/views/login/index.vue';
import Register from '@/views/register/index.vue';
import Forgotpw from '@/views/forgotpw/index.vue';
import Home from '@/views/home/index.vue';
import Info from '@/views/info/index.vue';
import NotAuth from '@/views/notAuth/index.vue';
import NotFound from '@/views/notFound/index.vue';

Vue.use(VueRouter);
var menus = JSON.parse(localStorage.getItem('menus'));
var child = [];
if (menus) {
    menus.forEach(x => {
        if (x.children) {
            var yChild = [];
            x.children.forEach(y => {
                // 创建路由配置
                let obj = {
                    path: y.path,
                    name: y.name,
                    component: () => import(`@/views${y.component}.vue`),
                    meta: y.meta
                };
                yChild.push(obj);
            });
        }
        // 创建路由配置
        let obj = {
            path: x.path,
            name: x.name,
            component: () => import(`@/views${x.component}.vue`),
            meta: x.meta,
            children: yChild
        };
        if (x.redirect) {
            obj.redirect = x.redirect;
        }
        // 将路由配置添加到 child 数组中
        child.push(obj);
    });
}

const routes = [
    {
        path: '/',
        component: Layout,
        meta: {
            title: '首页',
            isLogin: true
        },
        children: [
            {
                path: '/',
                name: 'home',
                component: Home,
                meta: {
                    title: '首页'
                }
            },
            {
                path: '/info',
                name: 'info',
                component: Info,
                meta: {
                    title: '个人中心'
                }
            },
            {
                path: '/templates',
                name: 'templates',
                component: () => import(`@/views/template/template.vue`),
                meta: {
                    title: '模板'
                }
            },
            ...child
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '登录页面'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: '注册账号'
        }
    },
    {
        path: '/forgotpw',
        name: 'forgotpw',
        component: Forgotpw,
        meta: {
            title: '找回密码'
        }
    },
    {
        path: '/notFound',
        name: 'notFound',
        component: NotFound,
        meta: {
            title: '找不到该页面'
        }
    },
    {
        path: '/notAuth',
        name: 'notAuth',
        component: NotAuth,
        meta: {
            title: '无权限'
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

const whiteList = ['/', '/info', '/templates']; //白名单

router.beforeEach((to, from, next) => {
    if (to.matched.some(ele => ele.meta.isLogin)) {
        if (localStorage.getItem('isLogin')) {
            let permissions = JSON.parse(localStorage.getItem('permissions'));
            if (whiteList.includes(to.path) || permissions.includes(to.path)) {
                // 其他情况允许访问
                next();
            } else {
                // 如果路由需要权限访问，并且用户没有该权限，重定向到无权限页面
                next('/notAuth');
            }
        } else {
            next('/login');
        }
    } else {
        if (to.matched.length === 0) {
            // 没有匹配到路由，重定向到404或者其他指定页面
            next('/notFound');
        } else {
            next();
        }
    }
});

// console.log(router.options.routes);//打印注册路由

export default router;
