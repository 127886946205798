<template>
    <el-breadcrumb separator="/" class="bread">
        <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
        <el-breadcrumb-item v-for="item in navs" :key="item.path" :to="{ path: item.path }">{{ item.meta.title }}</el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
export default {
    computed:{
        navs(){
            let routes = this.$route.matched;
            routes[0].path = '/';
            // console.log(routes);
            return routes;
        }
    },
    created(){
        // console.log(this.navs)
        // console.log(this.$route.matched);
    }
}
</script>

<style lang="less" scoped>
.bread{
    margin-bottom: 20px;
}
</style>