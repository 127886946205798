import Vue from 'vue'  
import VueI18n from 'vue-i18n'  
  
// 导入语言文件，这里假设它们是JS对象，其中包含所需的消息  
import enLocale from './en'  
import cnLocale from './cn'  
  
Vue.use(VueI18n)  
  
// 创建i18n实例并注册语言文件  
const i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'cn',  
    messages: {  
      cn: cnLocale,  
      en: enLocale,  
    }  
})

export default i18n;