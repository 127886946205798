const en = {
    header: {
        changePassword: "changePassword",
        logout: "logout",
        platformNotice: "platformNotice"
    },
    index: {
        hello: "hello",
        totalSales: "totalSales",
        orderNum: "orderNum",
        conversionRate: "conversionRate"
    }
}

export default en