<template>
    <div class="layout">
        <!-- 左侧导航栏 -->
        <div class="left-area">
            <Menu :isCollapse="isCollapse"></Menu>
        </div>
        <!-- 右侧内容 -->
        <div class="right-area" :class="{small:isCollapse}">
            <!-- 头部 -->
            <Header :isCollapse="isCollapse"  @chanMenu="chanMenu"></Header>
            <!-- 内容 -->
            <div class="content">
                <router-view></router-view>
            </div>  
        </div>
    </div>
</template>

<script>
import Menu from './menu/index.vue'
import Header from './header/index.vue'
export default {
    components:{
        Menu,
        Header,
    },
    data(){
        return{
            isCollapse:false,
        }
    },
    methods:{
        chanMenu(){
            this.isCollapse = !this.isCollapse;
        }
    },
}
</script>

<style lang="less" scoped>
.layout{
    .left-area{
        background-color: #34385c;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
    }
    .right-area{
        padding-left: 200px;
        .content{
            padding: 20px;
        }
    }
    .small{
        padding-left: 64px;
    }
}
</style>