<template>
    <div>
        无权限
    </div>
</template>

<script>
export default{

}

</script>