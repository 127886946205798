<!-- v-table -->
<template>
    <div class="">
        <!-- <el-alert class="m-b-5" type="info" effect="light">
            <span>{{ `已选中 ${selectList.length} 条数据` }}</span>
        </el-alert> -->

        <!-- table表格 -->
        <el-table :data="datas" :border="border" stripe style="width: 100%" @selection-change="handleSelectionChange">
            <template v-for="item in columns">
                <el-table-column v-if="item.type == 'index'" type="index" :key="item.prop || 'index'" :width="item.width || 55" label="#"></el-table-column>
                <el-table-column v-else-if="item.type == 'selection'" type="selection" :key="item.prop || 'selection'" :width="item.width || 55"></el-table-column>
                <el-table-column
                    v-else
                    :key="item.prop"
                    :fixed="item.fixed || false"
                    :sortable="item.sortable || false"
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.width"
                    :min-width="item.minWidth"
                >
                    <template slot-scope="scope">
                        <slot :name="item.prop" :data="scope.row">{{ scope.row[item.prop] }}</slot>
                    </template>
                </el-table-column>
            </template>
            <el-table-column v-if="showRightBar" fixed="right" label="操作" :width="rightBarwidth">
                <template slot-scope="scope">
                    <slot :row="scope.row"></slot>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty description="没有数据"></el-empty>
            </template>
        </el-table>

        <!-- 分页栏 -->
        <div class="m-t-10 text-right">
            <el-pagination
                background
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            >
                <slot name="page"></slot>
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        /**
         * 字段配置
         * type: index 角标 | selection 选择框
         */
        columns: {
            type: Array,
            default: () => []
        },
        // 数据
        data: {
            type: Array,
            default: () => []
        },
        // 高度
        height: {
            type: [Number, String],
            default: () => ''
        },
        // 是否显示表格边框
        border: {
            type: Boolean,
            default: false
        },
        // 是否显示右侧操作栏
        showRightBar: {
            type: Boolean,
            default: true
        },
        // 右侧操作栏宽度
        rightBarwidth: {
            type: [Number],
            default: 190
        },
        // 分页栏：当前页数
        currentPage: {
            type: [Number],
            default: 1
        },
        // 分页栏：数据总数
        total: {
            type: [Number, String],
            default: 0
        },
        // 分页数量
        pageSize: {
            type: [Number],
            default: 10
        },
        // 分页栏 下拉分页数
        pageSizes: {
            type: Array,
            default: () => [10, 20, 30, 50, 100]
        }
    },
    computed: {
        datas() {
            return this.data;
        },
        totals() {
            return typeof this.total === 'string' ? parseInt(this.total) : this.total;
        }
    },
    data() {
        return {
            selectList: []
        };
    },
    created() {},
    mounted() {},
    methods: {
        // 当选择项发生变化时会触发该事件 回调：已选中list
        handleSelectionChange(e) {
            this.selectList = e;
            this.$emit('on-selection-change', e);
        },
        // pageSize 改变时会触发 e：每页条数
        handleSizeChange(e) {
            // this.$emit('on-size-change', e);
            this.$emit('on-current-click', { type: 'size', current: e });
        },
        // currentPage 改变时会触发 e：当前页
        handleCurrentChange(e) {
            this.$emit('on-current-click', { type: 'change', current: e });
        },
        // 用户点击上一页按钮改变当前页后触发 e：当前页
        handlePrevClick(e) {
            this.$emit('on-current-click', { type: 'prev', current: e });
        },
        // 用户点击下一页按钮改变当前页后触发 e：当前页
        handleNextClick(e) {
            this.$emit('on-current-click', { type: 'next', current: e });
        }
    }
};
</script>

<style lang="less" scoped></style>
