import request from "@/utils/request";

export function getCheckRole(){
  return request({
    url: '/admin_role/check_role',
    method: 'get',
  })
}

export function getRoleList(data){
  return request({
    url: '/admin_role/list',
    method: 'post',
    data: data,
  })
}

export function getRoleInfo(id){
  return request({
    url: '/admin_role/info/' + id,
    method: 'get',
  })
}

export function addRole(data){
  return request({
    url: '/admin_role/add',
    method: 'post',
    data: data,
  })
}

export function editRole(id, data){
  return request({
    url: '/admin_role/edit/' + id,
    method: 'put',
    data: data,
  })
}

export function delRole(data){
  return request({
    url: '/admin_role/del',
    method: 'post',
    data: data,
  })
}
