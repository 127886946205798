import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import i18n from './languages';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/normalize.css';
import '@/assets/css/base.css';
import '@/styles/index.less';
import Pagination from '@/components/Pagination/Pagination.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import PageTable from '@/views/components/page-table/page-table.vue';

Vue.component('Pagination', Pagination);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('page-table', PageTable);

Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app');
