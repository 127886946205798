<template>
    <div class="forgotpw-container">
        <el-form ref="forgotpwForm" :model="forgotpwForm" :rules="forgotpwRules" class="forgotpw-form" autocomplete="on" label-position="left">
            <h3 class="forgotpw-title">找回密码</h3>
            <el-form-item porp="username">
                <el-input ref="username" v-model="forgotpwForm.username" placeholder="请输入账号" name="username" type="text" tabindex="1" autocomplete="on">账号</el-input>
            </el-form-item>
            <el-form-item porp="old_password">
                <el-input ref="old_password" v-model="forgotpwForm.old_password" placeholder="请输入账号" name="old_password" type="text" tabindex="2" autocomplete="on">旧密码</el-input>
            </el-form-item>
            <el-form-item porp="password">
                <el-input ref="password" v-model="forgotpwForm.password" placeholder="请输入密码" name="password" type="text" tabindex="3" autocomplete="on" @keyup.enter.native="submitForm">
                    密码
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button :loading="loading" type="primary" style="width: 100%" @click.native.prevent="submitForm">立即找回</el-button>
            </el-form-item>
            <el-form-item>
                <a href="/login">返回登录！</a>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { forgotpw } from '@/utils/servers/adminUser.js';
export default {
    name: 'Forgotpw',
    data() {
        return {
            forgotpwForm: {
                username: '',
                old_password: '',
                password: ''
            },
            forgotpwRules: {
                username: [{ required: true, trigger: 'blur' }],
                old_password: [{ required: true, trigger: 'blur' }],
                password: [{ required: true, trigger: 'blur' }]
            },
            loading: false
        };
    },
    methods: {
        submitForm() {
            forgotpw(this.forgotpwForm).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.$message.success(data.msg);
                    // 跳转登录
                    this.$router.push({ path: '/login' });
                } else {
                    this.$message.error(data.msg);
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.forgotpw-container {
    width: 100vw;
    height: 100vh;
    background-image: url('~@/assets/img/login-bg.jpg');
    background-size: cover;
    overflow: hidden;
}
.forgotpw-form {
    border-radius: 15px;
    margin: auto;
    margin-top: 180px;
    width: 350px;
    padding: 25px 35px 25px 35px;
    background: aliceblue;
}
.forgotpw-title {
    margin: 0px auto 48px auto;
    text-align: center;
    font-size: 40px;
}
</style>
