<template>
    <div>
        <el-menu
        :default-active="active"
        class="el-menu-vertical-demo"
        background-color="#34385c"
        text-color="#fff"
        active-text-color="#ffd04b"
        router
        :unique-opened = "true"
        :collapse="isCollapse">
            <el-menu-item>
                <span slot="title">Vue + Element-UI</span>
            </el-menu-item>
            <el-menu-item index="/">
                <i class="el-icon-s-home"></i>首页
            </el-menu-item>
            <template v-for="menu in menus">
                <el-menu-item v-if="!menu.children" :key="menu.path" :index="menu.path">
                <i v-if="menu.icon" :class="menu.icon"></i>{{ menu.meta.title }}</el-menu-item>
                <el-submenu v-else :key="menu.path" :index="menu.path">
                <template slot="title">
                <i v-if="menu.icon" :class="menu.icon"></i>{{ menu.meta.title }}</template>
                    <el-menu-item v-if="child.isShow" v-for="child in menu.children" :key="menu.path + '/' + child.path" :index="menu.path + '/' + child.path">
                <i v-if="child.icon" :class="menu.icon"></i>{{ child.meta.title }}</el-menu-item>
                </el-submenu>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props:['isCollapse'],
    data(){
        return{
            active: this.$route.meta.activeMenu ? this.$route.meta.activeMenu : this.$route.path,
        }
    },
    computed:{
        ...mapGetters(["menus"])
    },
    watch:{
        $route(to, from){
            let { meta, path } = to
            this.active = meta.activeMenu ? meta.activeMenu : path
        }
    },
}
</script>

<style>
.el-menu{
    border-right:0px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
</style>