<template>
    <div class="home">
        <div class="header">
            <el-row :gutter="40">
                <el-col :span="6">
                    <div class="item">
                        <el-col :span="12">日访问量:</el-col>
                        <el-col :span="12">{{ dayVisit }}</el-col>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="item">
                        <el-col :span="12">日销售额:</el-col>
                        <el-col :span="12">{{ daySale }}</el-col>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="item">
                        <el-col :span="12">月访问量:</el-col>
                        <el-col :span="12">{{ monthVisit }}</el-col>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="item">
                        <el-col :span="12">月销售额:</el-col>
                        <el-col :span="12">{{ monthSale }}</el-col>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="content">
            <el-row :gutter="40">
                <el-col :span="12">
                    <div class="chart" id="data1"></div>
                </el-col>
                <el-col :span="12">
                    <div class="chart" id="data2"></div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import { getData } from '@/utils/servers/base.js';
export default {
    data() {
        return {
            dayVisit: 0,
            daySale: 0,
            monthVisit: 0,
            monthSale: 0,
            sales: {
                ts: [],
                list: []
            },
            sales_ratio: {
                ts: [],
                list1: [],
                list2: []
            }
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            getData().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.dayVisit = data.data.dayVisit;
                    this.daySale = data.data.daySale;
                    this.monthVisit = data.data.monthVisit;
                    this.monthSale = data.data.monthSale;
                    this.sales.ts = data.data.sales.ts;
                    this.sales.list = data.data.sales.list;
                    this.sales_ratio.ts = data.data.sales_ratio.ts;
                    this.sales_ratio.list1 = data.data.sales_ratio.list1;
                    this.sales_ratio.list2 = data.data.sales_ratio.list2;
                    this.initChart();
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        initChart() {
            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById('data1'));
            var xAxisData = this.sales.ts;
            var data = this.sales.list;
            // 绘制图表
            myChart.setOption({
                title: {
                    text: '月销售额'
                },
                tooltip: {},
                xAxis: {
                    data: xAxisData
                },
                yAxis: {},
                series: [
                    {
                        type: 'bar',
                        data: data
                    }
                ]
            });

            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById('data2'));
            var xAxisData = this.sales_ratio.ts;
            var data1 = this.sales_ratio.list1;
            var data2 = this.sales_ratio.list2;
            // 绘制图表
            myChart.setOption({
                title: {
                    text: '月销售比例'
                },
                tooltip: {},
                xAxis: {
                    data: xAxisData
                },
                yAxis: {},
                series: [
                    {
                        data: data1,
                        type: 'line',
                        areaStyle: {}
                    },
                    {
                        data: data2,
                        type: 'line',
                        areaStyle: {
                            color: '#ff0',
                            opacity: 0.5
                        }
                    }
                ]
            });
        }
    }
};
</script>

<style lang="less" scoped>
.home {
    .header {
        margin-bottom: 20px;
        .item {
            height: 100px;
            line-height: 100px;
            background: aquamarine;
            border-radius: 10px;
            text-align: center;
        }
    }
    .content {
        .chart {
            padding: 10px;
            border: 1px solid #ccc;
            height: 400px;
        }
    }
}
</style>
