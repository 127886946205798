<template>
    <router-view></router-view>
</template>

<script>
import { getRoleInfo } from '@/utils/servers/adminRole.js';
import { mapMutations } from 'vuex';
export default {
    name: 'app',
    // 在页面加载时，从 LocalStorage 中获取用户信息并存储到 Vuex 中
    created() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const token = localStorage.getItem('token');
        if (userInfo) {
            // 登录成功
            this.setIsLogin(true);
            // 保存用户信息
            this.setUserInfo(userInfo);
            // 保存用户信息
            this.setToken(token);
            //获取当前用户角色权限
            this.getRoleInfo(userInfo.id);
        }
    },
    methods: {
        ...mapMutations({ setIsLogin: 'setIsLogin', setUserInfo: 'setUserInfo', setToken: 'setToken', setMenus: 'setMenus', setPermissions: 'setPermissions' }),
        getRoleInfo(id) {
            getRoleInfo(id).then(res => {
                const { data, code, msg } = res;
                if (code == 1) {
                    // 保存菜单信息
                    this.setMenus(data.menus);
                    this.setPermissions(data.permissions);
                } else {
                    this.$message.error(msg);
                }
            });
        }
    }
};
</script>
