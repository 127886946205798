const cn = {
    header: {
        changePassword: "个人信息",
        logout: "退出",
        platformNotice: "平台通知"
    },
    index: {
        hello: "您好",
        totalSales: "总销售额",
        orderNum: "订单数量",
        conversionRate: "转换率"
    }
}

export default cn